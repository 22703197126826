/* mobile view */
  @media (max-width: 767px) {
    .delete-picture-button{
      right: 7vw;
      bottom: 17vh;
    }
    .prev-icon{
      scale: 0.04;
      left: 8vw;
    }
    
    .next-icon{
      scale: 0.04;
      right: 8vw;
    }
    .halftone {
      --halftone-size: 2px;
      --halftone-bleed: 1.3;
      --halftone-separate-k: 1;
      --halftone-dot-size: calc(var(--halftone-size)* var(--halftone-bleed));
      --halftone-color-dot-size: var(--halftone-dot-size);
      --halftone-rotation: -18deg;
      position: relative;
      filter: brightness(calc(0.5 + var(--halftone-bleed)* 0.3 - var(--halftone-separate-k)* 0.02)) blur(calc(var(--halftone-size)* 0)) contrast(1.1) ;
    }
  }

  /* tablet  */
  @media (min-width: 768px) and (max-width:1279px){
    .delete-picture-button{
      right: 5vw;
      bottom: 4vh;
    }
    .prev-icon{
      scale: 0.05;
      left: 3vw;
    }
    
    .next-icon{
      scale: 0.05;
      right: 3vw;
    }
    .halftone {
      --halftone-size: 2px;
      --halftone-bleed: 1;
      --halftone-separate-k: 2;
      --halftone-dot-size: calc(var(--halftone-size)* var(--halftone-bleed));
      --halftone-color-dot-size: var(--halftone-dot-size);
      --halftone-rotation: -18deg;
      position: relative;
      filter: brightness(calc(0.5 + var(--halftone-bleed)* 0.3 - var(--halftone-separate-k)* 0.02)) blur(calc(var(--halftone-size)* 0.1)) contrast(1.1) blur(0.3px);

    }
  }

  /* desktop */
  @media (min-width: 1280px) {
    .delete-picture-button{
      right: 2vw;
      bottom: 4vh;
    }
    .prev-icon{
      scale: 0.05;
      left: 3vw;
    }
    
    .next-icon{
      scale: 0.05;
      right: 3vw;
      opacity:0.8;
    }
    .halftone {
      --halftone-size: 2px;
      --halftone-bleed: 1;
      --halftone-separate-k: 2;
      --halftone-dot-size: calc(var(--halftone-size)* var(--halftone-bleed));
      --halftone-color-dot-size: var(--halftone-dot-size);
      --halftone-rotation: -18deg;
      position: relative;
      filter: brightness(calc(0.5 + var(--halftone-bleed)* 0.3 - var(--halftone-separate-k)* 0.02)) blur(calc(var(--halftone-size)* 0.1)) contrast(1.1) blur(0.3px);

    }

  }

  .prev-icon{
    z-index: 100;
    width: fit-content;
    filter: invert(1);
    position: relative;
  }
  
  .next-icon{
    z-index: 100;
    width: fit-content;
    filter: invert(1);
    position: relative;
    transform: rotate(180deg);
  }

.left-arrow{
    width: 10%;
    font-size: 40px;
    color:rgba(255, 255, 255, 0.7);
    transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);

}

.right-arrow{
    width: 10%;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.7);
    transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.middle-part{
    width: 100%;
    
}


.carousel-control-next, .carousel-control-prev {
    z-index: 100;
}

  .halftone img {
    object-fit: cover; /* Cover the entire container without stretching */
    width: 100%;
    height: 100%; /* Limit the height to 100vh */
    object-position: center;
}
.blog-single-content {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0; /* Ensure no gap between the image and content */
  /* background-color: white; Set a background color to contrast with the image */
}
  .halftone::after {
    content:'';
    position: absolute;
    inset:0;
    background-image:   radial-gradient(var(--halftone-color-dot-size) at center, #000, #666, #ccc, #fff),
                        radial-gradient(var(--halftone-color-dot-size) at center, #000, #fff),
                        radial-gradient(var(--halftone-color-dot-size) at center, black, white),
                        radial-gradient(var(--halftone-color-dot-size) at center, cyan, white),
                        radial-gradient(var(--halftone-color-dot-size) at center, magenta, white),
                        radial-gradient(var(--halftone-color-dot-size) at center, yellow, white);
    background-size: 2.5px 2.5px;
    mix-blend-mode: screen;
    background-blend-mode: multiply;

  }
  .halftone-k{
    --halftone-size: 2px;
    --halftone-bleed: 1;
    --halftone-separate-k: 2;
    --halftone-dot-size: calc(var(--halftone-size)* var(--halftone-bleed));
    --halftone-color-dot-size: var(--halftone-dot-size);
    --halftone-rotation: -18deg;
    position: relative;
    filter: brightness(calc(0.5 + var(--halftone-bleed)* 0.3 - var(--halftone-separate-k)* 0.02)) blur(calc(var(--halftone-size)* 0.1)) contrast(1.1) blur(0.6px);
    background-image: radial-gradient(var(--halftone-color-dot-size) at 25% 25%, #000, #666, #ccc, #fff), 
                                    radial-gradient(var(--halftone-color-dot-size) at 75% 75%, #000, #fff),
                                    radial-gradient(10px at center, black, white),
      radial-gradient(10px at 5px 5px, cyan, white),
      radial-gradient(10px at 15px 5px, magenta, white),
      radial-gradient(10px at 10px 15px, yellow, white);
      transform: rotate(30deg);
    background-size: 3.5px 4.5px;
    mix-blend-mode: screen;
    background-blend-mode: multiply;
}

  /* .halftone-k::after{
    content: "";
    position: absolute;
    inset: -30%;
    background-size: var(--halftone-size) var(--halftone-size);
    background-blend-mode: multiply;
    mix-blend-mode: screen;
    transform: rotate(30deg);
    background-image: radial-gradient(var(--halftone-color-dot-size) at 25% 25%, #000, #666, #ccc, #fff), radial-gradient(var(--halftone-color-dot-size) at 75% 75%, #000, #fff);
    mix-blend-mode: screen; 
  } */

  .halftone-color-overlay{
    width: inherit;
    height: 100vh;
    z-index: 10;
    position: absolute;
    top: 0px;
    /* background-color: rgba(255, 0, 0, 0.5); Temporary color to test visibility */

    /* background-image: url('../assets/images/textures/photocopies.png'); */
  }

  .delete-picture-button{
    /* right: 2vw;
    bottom: 4vh; */
    position: absolute;
    z-index: 100;
    /* width: 30px; */
    height: 32px;
    cursor: 'pointer';
  }

  .delete-picture-button:hover{
    cursor: 'pointer';
    transform: scale(1.05);
    filter:invert(1);
    transition: transform 0.2s ease-in-out;
  }