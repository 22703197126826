$yellow:#f5bc04;
// @font-face {
//     font-family: 'ruslan-display';
//     src: url('../src/assets/fonts/RuslanDisplay-Regular.ttf');
// }
// @font-face {
//     font-family: 'bungee-shade';
//     src: url('../src/assets/fonts/BungeeShade-Regular.ttf');
// }
// @font-face {
//     font-family: 'squada';
//     src: url('../src/assets/fonts/SquadaOne-Regular.ttf');
// }

// @font-face {
//     font-family: 'bungee';
//     src: url('../src/assets/fonts/Bungee-Regular.ttf');
// }

@font-face {
    font-family: 'poppins';
    src: url('../src/assets/fonts/Poppins-Regular.ttf');

}
	
@font-face {
	font-family: 'glassure';
	src: url('../src/assets/fonts/Glassure.otf');
}

@font-face {
	font-family: 'calligraffitti';
	src: url('../src/assets/fonts/Calligraffitti-Regular.ttf');
}

@font-face {
	font-family: 'poppins-med';
	src: url('../src/assets/fonts/Poppins-Medium.ttf');
}

	// @font-face {
	// 	font-family: 'babylonica';
	// 	src: url('../src/assets/fonts/Babylonica-Regular.ttf');
	// }
	
@font-face {
	font-family: 'glassure';
	src: url('../src/assets/fonts/Glassure.otf');
}

@font-face {
	font-family: 'calligraffitti';
	src: url('../src/assets/fonts/Calligraffitti-Regular.ttf');
}

@font-face {
	font-family: 'gela';
	src: url('../src/assets/fonts/GelaTrialVF.ttf');
}
@font-face {
	font-family: 'tropikal';
	src: url('../src/assets/fonts/Tropikal-Bold.otf');
}
@font-face {
	font-family: 'cutout';
	src: url('../src/assets/fonts/Magazine-Cutouts-Font.ttf');
}

@font-face {
	font-family: 'justaword';
	src: url('../src/assets/fonts/Justaword.ttf');
}

@font-face {
	font-family: 'feelings';
	src: url('../src/assets/fonts/FeelingsOnOff.ttf');
}


@font-face {
	font-family: 'standard';
	src: url('../src/assets/fonts/JosefinSans.ttf');
}
// @font-face {
// 	font-family: 'standard';
// 	src: url('../src/assets/fonts/raleway.ttf');
// }
	
:root {
    --magic-yellow:#ffe550;
	--yellow-dark: #d2b138; 
	--navyBlue: #2d4bd1;
	--whimsyPink:#e36bae;
	--dark-complement:#0f0d0a;
	--outline-color: #414141;
	--grey-accent: #e3e3e3cf;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		-o-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		-o-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	-moz-clip-path: $clip;
	-ms-clip-path: $clip;
	clip-path: $clip;
}

@mixin transition($transition) {
	-moz-transition:    all $transition ease;
	-o-transition:      all $transition ease;
	-webkit-transition: all $transition ease;
	-ms-transition: 	all $transition ease;
	transition:         all $transition ease;
}

.padding {
	padding-left: 0!important;
	padding-right: 0!important;
}

//Scrollbar
/* Define scrollbar track color */
:root {
	--scrollbar-track-color: transparent;
  }
  
  /* Define scrollbar thumb color */
  :root {
	--scrollbar-thumb-color: var(--magic-yellow);
  }
  
  /* Define scrollbar thumb hover color */
  :root {
	--scrollbar-thumb-hover-color: var(--yellow-dark);
  }
  
  /* Define scrollbar width and height */
  :root {
	--scrollbar-width: 6px;
	--scrollbar-height: 8px;

  }
  
  /* Define scrollbar styles */
  ::-webkit-scrollbar {
	width: var(--scrollbar-width);
	height: var(--scrollbar-height);
	scrollbar-shadow-color: 1px 1px 5px var(--magic-yellow);
  }
  
  /* Define scrollbar track styles */
  ::-webkit-scrollbar-track {
	background-color: var(--scrollbar-track-color);
  }
  
  /* Define scrollbar thumb styles */
  ::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb-color);
	border-radius: 10px;
  }
  
  /* Define scrollbar thumb hover styles */
  ::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-thumb-hover-color);
  }
// mobile view
  @media (max-width: 767px) {
	.blocks-loading {
		transform: scale(1);
		height: 100%;
		width: inherit;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
	  }
	.eye-menu-bar{
		width: 90px;
		height: 65px;
	}
	.search-box {
		margin-top: 3vh;
	}
	.blog-input-container{
		width:100%;
		font-size: 14px;
		font-family: 'feelings';
	}
	.blog.container-fluid{
		width: 100vw;
		font-family: 'cutout';
		padding: 1vh 3vw 0vh 3vw;
		color:var(--dark-complement);
		background: var(--dark-complement);
	}
	.input-text-box {
		@include border-radius(0!important);
		font-size: 12px!important;
		height: 35px!important;
		letter-spacing: 1px!important;  
	}
	.login.container-fluid {
		padding-bottom: 30px;
		padding-top: 30px;
		width: 75vw;
		font-family: 'standard';
		color:var(--grey-accent);
		background:var(--dark-complement);
	}
	.btn-sign-in {
		background:var(--yellow-dark);
		@include border-radius(0!important);
		color: var(--dark-complement);
		padding: 10px 20px!important;
		border-radius: 5px !important;
		width: 100%;
		font-size: 15px;
		// transition: color .4s ease, box-shadow .4s ease, width .4s ease-in-out;
	
	}
	.width-login-container{
		width:100%;
	}
	.standardLayout{
		overflow-y:hidden;
		height:87vh;
	}
	
	.blog-title-box{
		.blog-title{
			bottom: 35vh;
			padding: 30px;
		}
		.single & {
			height: 87vh;
			}
	}
	.mx-server{
		height:40vh;
	}
	.search-results-header{
		font-size: 2.5em;
	}
	.placecounter-number{
		font-size: 2.5em;
		margin-bottom: 0;
	}
	
	.placecounter-label{
		font-size: 0.95em;  
	}
	
	.latest-posts{
		font-size: 3em;
		padding: 0 0 0 0;
	}
	.flex-across-counter{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.counter-unit{
		width:31vw;
	}
	// .place{
		// width:100vw;
	// }
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		width: 62vw;
	  }
	.search-box {
		border: solid 0.3em #f1f1f1;
	}
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		transform: scale(1) translateX(1em);
	}

	.search-box{
		margin-right: 0vw;
	}

	  .blog-title {
        .single & {
			// height: 100%;
			margin: 0vh auto;
		}
        h2 {
			
			font-weight: 300;
            .single & {
				font-size: 2em;
				font-weight:400;
				// text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
			}
        }

        > span {
			font-size: 15px;
			margin-bottom: 10px;
        }
    }
	.blog-single-content {
		.meta-info{
			font-size: 1em;
		}
		// .author {
		// 	font-size: 1em !important;
		//   }
	
		// ul li {
		// }
		p{
			font-size: 1.2em;
		}
	}

  }

 
//Loading

  
  .block-loading {
	--sz: 8vmin;
	--tX: 0;
	--animation: 700ms cubic-bezier(0.3, 0.5, 0.4, 0.9) infinite alternate-reverse;
	--hm: 4.5vmin;
	height: var(--sz);
	width: var(--sz);
	background-image: var(--bg);
	border-radius: 50%;
	transform: translateX(var(--tX));
	mix-blend-mode: lighten;
  }
  
  .orange {
	--bg: linear-gradient(-50deg, var(--magic-yellow) 0%, hwb(43 25% 0%) 100%);
	margin-right: var(--hm);
	animation: attract-orange var(--animation);
  }
  
  .blue {
	--bg: linear-gradient(50deg, #2d4bd1 0%, #7e8fde 100%);
	margin-left: var(--hm);
	animation: attract-blue var(--animation);
  }
  
  @keyframes attract-orange {
	to {
	  transform: translateX(calc(var(--sz) + calc(var(--hm) / 4)));
	}
  }
  
  @keyframes attract-blue {
	to {
	  transform: translateX(calc(var(--sz) * -1 - calc(var(--hm) / 4)));
	}
  }
  
  

// Header

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
	color: #fff!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	background: $yellow!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));
}
.navbar-light .navbar-nav .nav-link {
	color: #222!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	position: relative!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));

	&:focus, &:hover {
		background: $yellow!important;
		color: #fff!important;
		padding-top: 12px!important;
		padding-bottom: 15px!important;
		padding-left: 20px!important;
		padding-right: 20px!important;
		margin: 0 20px;
		position: relative;
	}
}

.bg-faded {
    background-color: #f7f7f7;
  }

.mobile_logo_width {
	display: none;
}

.navbar-nav {
 flex-direction: row !important;
 cursor: pointer;   
}

// form
.heading {
    font-size: 3em;
	// color: #424040;
    // font-weight: 600;
}



.form-control:focus {
    box-shadow: none !important;
    border-color: $yellow !important;
}



.btn-sign-in:hover {
    background: var(--magic-yellow);
	color: var(--dark-complement);
	transition: .4s cubic-bezier(0.33, 0.66, 0.66, 1), width .4s ease; 
	box-shadow: 0px 0px 8px var(--magic-yellow);

}


.btn-sign-up {
    background:var(--magic-yellow);
    @include border-radius(0!important);
	color: var(--dark-complement);
	padding: 10px 50px!important;
    border-radius: 5px !important;
}

.btn-sign-up:hover {
    background: var(--yellow-dark);
}

.blog-form input:focus {
    border-color: $yellow !important;
}

.description-box {
    @include border-radius(0!important);
	height: 150px;
	resize: none;
	padding-top: 8px !important;
	letter-spacing: 1px!important; 
}

.btn-add {
    background: var(--yellow-dark) !important;
	@include border-radius(0!important);
	color: var(--dark-complement);
	padding: 10px 50px!important;
}
.btn-add:hover {
    background: var(--magic-yellow) !important;
	
}

.react-tag-input {
    height: 45px !important;
}

.react-tag-input:focus {
    border-color: $yellow !important;   
}

.catg-dropdown {
    width: 100%;
	padding-left: 5px;
    border-radius: 0px;
    height: 45px;
    color: #806a78;
  }
  
  .catg-dropdown:focus {
      outline: none !important;
      border-color: $yellow;
  }
  
  .trending {
      float: left;
      color: #806a78;
      font-size: 18px;
      margin: auto;
  }

  //blog detail 
  .p-text-start{
	line-height: 1.6em;
	text-align: justify;
	white-space: pre-line;
  }
  .p-text-start::first-letter{
	font-size: 4em; /* Increase the size of the first letter */
	float: left; /* Floats the first letter to the left of the paragraph */
	line-height: 0.8em; /* Keeps the large letter's line-height normal */
	margin-right: 10px; /* Adds some spacing between the large letter and text */
	font-weight: bold; /* Make the first letter bold */
	color: #000; 
  }
  //tablet
  @media (min-width: 768px) and (max-width:1279px){
	.blocks-loading {
		// transform: scale(1.3);
		height: 100%;
		width: inherit;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
	  }
	.eye-menu-bar{
		width: 105px;
		height: 80px;
	}
	.search-box {
		  margin-top: 2vh;
	}
	.blog-input-container{
		width:100%;
		font-size: 16px;
		font-family: 'feelings';
	}
	.blog.container-fluid{
		width: 85vw;
		font-family: 'cutout';
		padding: 6vh 3vw 2vh 3vw;
		color:var(--dark-complement);
		background: var(--dark-complement);
	}
	.input-text-box {
		@include border-radius(0!important);
		font-size: 14px!important;
		height: 45px!important;
		letter-spacing: 1px!important;  
	}
	.login.container-fluid {
		padding-bottom: 30px;
		padding-top: 30px;
		width: 50vw;
		font-family: 'standard';
		color:var(--grey-accent);
		background:var(--dark-complement);
	}
	.btn-sign-in {
		background:var(--yellow-dark);
		@include border-radius(0!important);
		color: var(--dark-complement);
		padding: 10px 50px!important;
		border-radius: 5px !important;
		width: 100%;
		font-size: 15px;
		// transition: color .4s ease, box-shadow .4s ease, width .4s ease-in-out;
	
	}
	.width-login-container{
		width:85%;
	}
	.standardLayout{
		height: 100vh;
	}
	.blog-title-box{
		.blog-title{
			bottom: 38vh;
			padding: 0 14vw 0 15vw;
		}
		.single & {
			height: 100vh;
			}
	}
	.mx-server{
		height:40vh;
	}
	.search-results-header{
		font-size: 3em;
	}
	.placecounter-number{
		font-size: 4em;
		margin-bottom: 0;
	}
	.placecounter-label{
		font-size: 1.3em;
	}

	// .side-menu-button{
	// 	padding-top: 4vh;
	// }
	.latest-posts{
		font-size: 5em;
		padding:0;
	}
	// .place{
	// 	width:37vw;
	// }
	.counter-unit{
		width: 34vw;
	}
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		width: 50vw;
	}
	.search-box {
		border: solid 0.3em #f1f1f1;
	}
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		transform: scale(1) translateX(1.5em);
	}
	.blog-title {
		.single & {
			// height: 100%;
			margin: 0vh auto;
		}
		h2 {
			font-weight: 300;
			.single & {
				font-size: 4em;
				font-weight:400;
				// text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
			}
		}

		> span {
			font-size: 15px;
			margin-bottom: 10px;
		}

  }
  .blog-single-content {
	.meta-info{
		font-size: 1.2em;
	}
	// .author {
	// 	font-size: 1em !important;
	//   }

	// ul li {
	// }
	p{
		font-size: 1.2em;
	}
	}
}
  //desktop
	@media (min-width: 1280px) {
		.blocks-loading {
			// transform: scale(1.3);
			height: inherit;
			// width: 37vw;
			display: flex;
			align-items: center;
			position: relative;
			justify-content: center;
			
		  }
		.eye-menu-bar{
			width: 105px;
			height: 80px;
		}
		.search-box {
			margin-top: 0.5em;
	  	}	
		.blog-input-container{
			width:100%;
			font-size: 16px;
			font-family: 'feelings';
		}
		.blog.container-fluid{
			width: 60vw;
			font-family: 'cutout';
			padding: 6vh 3vw 2vh 3vw;
			color:var(--dark-complement);
			background: var(--dark-complement);
		}
		.input-text-box {
			@include border-radius(0!important);
			font-size: 14px!important;
			height: 45px!important;
			letter-spacing: 1px!important;  
		}
		.login.container-fluid {
			padding-bottom: 30px;
			padding-top: 30px;
			width: 30vw;
			font-family: 'standard';
			color:var(--grey-accent);
			background:var(--dark-complement);
		}
		.btn-sign-in {
			background:var(--yellow-dark);
			@include border-radius(0!important);
			color: var(--dark-complement);
			padding: 10px 50px!important;
			border-radius: 5px !important;
			width: 100%;
			font-size: 15px;
			// transition: color .4s ease, box-shadow .4s ease, width .4s ease-in-out;
		
		}
		.width-login-container{
			width:70%;
		}
		.standardLayout{
			height: 100vh;
		}
		.blog-title-box{
			.blog-title{
				bottom: 42vh;
        		padding: 0vw 35vh 10px 35vh;
			}
			.single & {
				height: 100vh;
				}
		}
		.mx-server{
			height:50vh;
		}
		.search-results-header{
			font-size: 4em;
		}
		.counter-unit{
			width:23vw;
		}
		.placecounter-number{
			font-size: 3.3em;
		}
		.placecounter-label{
			font-size: 1.3em;   
		}
		.side-menu-button{
			padding-top: 2vh;
        	padding-left: 3vw;
		}
		.latest-posts{
			font-size: 5.7em;
			padding: 5vh 0px 1vh 0;
		}
		.blog-title {
			.single & {
				// height: 100%;
				margin: 0vh auto;
			}
			h2 {
				padding: 0 5vw 0 5vw;
				font-weight: 300;
				.single & {
					font-size: 4em;
					font-weight:400;
					// text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
				}
			}
	
			> span {
				font-size: 15px;
				margin-bottom: 10px;
			}
	}
	.blog-single-content {
		.meta-info{
			font-size:1.2em;
		}
		// .author {
		// 	font-size: 1em !important;
		//   }
	
		// ul li {
		// }
		p{
			font-size: 1.2em;
		}
	}
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		width: 28vw;
	}
	.search-box {
		border: solid 0.3em #f1f1f1;
	}
	.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
		transform: scale(1) translateX(1em);
	}
}


//PLace Counter
.place{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	text-align:center;
	// width:37vw;
}
.placecounter-label{
	font-family: "standard";
	// font-weight: 400;
	color:var(--grey-accent);
}
.placecounter-number{
	font-family: "cutout";
	// font-weight: 800;
	color: var(--magic-yellow);
	// font-size: 68px;
	// text-transform: uppercase;
}
.flex-across-counter{
	display: flex;
    flex-direction: row;
    justify-content: center;
}
.counter-unit{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.side-menu-button{
	position: absolute;
	// width: 85px;
	// height: 59px;
	z-index: 10;
	color: rgba(255, 255, 255, 0.20);
	font-size: 22px;
	display: flex;
	justify-content: flex-begin;
	// position: relative;
	
	
}
//   Blog Section
.latest-posts{
	display: flex;
    justify-content: center;
	// padding: 0vh 0vh 0vh 0vw;
    font-family: "cutout";
    color: whitesmoke;
}

.layout-footer{
	flex-grow: 0;
	flex-shrink: 0;
	// flex-basis: auto;
}

.standardLayout{
    // height: 100vh;
	width: 100%;
    display: flex;
    flex-direction: column;
	align-items: center;
    justify-content: space-between;
		// margin:50px;
	// border: 1.5px solid #EAFF82;
}

.rectangle-border{
	height:100%;
	// margin: 10vh 0vw 0 0vw;
    // border: 8px solid var(--dark-complement);
	display: flex;
    flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
}




.page-body {
    flex-grow: 1;
}

.basicLayout{
	box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
	// align-items: center;

}
.mapLayout{
	box-sizing: border-box;
    min-height: 100%;
	width:100%;
    display: flex;
    flex-direction: column;
	align-items: center;
	height: 100%;
	overflow-y: hidden;
}
main {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
  }


.blog-heading-reg {
	font-size: 24px;
	color: #222;
    // border-bottom: 1px solid #777;
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
    flex-direction: row;
}

.blog-heading-map {
	flex: 0 0 auto;
	font-size: 24px;
    color: #222;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: auto; 
    max-width: 100%;
}

.blogs-img {
    height: 200px;
	@include border-radius(5px);
	overflow: hidden;
	position: relative;
	top: 0;
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0));
	@include transition(all 0.5s);
}

.btn-close{
	color:white;
}
// .list-left a:hover{
//     color: rgba(0,0,0, 0.9);
//     -webkit-text-stroke: 1px rgba(0,0,0,0.8);
//     // @include transition(0.3s);
// 	transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
// }

// .side-bar-list-down{
// 	display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     height: 48vh;
//     font-size: larger;
// 	padding-right: 40px;
// }

// .side-bar-icon{
// 	margin-right: 20px;
// 	color: var(--dark-complement);
// }

// .side-bar-list-down li:hover{
// 	transition: 0.4s cubic-bezier(0.33, 0.66, 0.66, 1);
//  transform: scale(1.1);
// }

// .side-bar-list{
// 	display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

// }

// .side-bar-list a{
// 	color: var(--dark-complement);
// }


.blogs-img > img {
    height: 260px;
	min-width: 100%;
}

.hover-blogs-img:hover > .blogs-img {
    top: -7px;
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
}

.title {
    font-size: 17px;
	color: #222!important;
	font-weight: 800;
    font-family: "Poppins";
    text-decoration: none;
	display: block;
	height: 63px;
}


.category {
    display: inline-block;
    position: relative;
    background-color: #4287f5;
    padding: 4px 8px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    text-transform: none;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
}

.fa-trash {
	color: #C85A42 !important;
}

.fa-edit {
	color: lightsalmon !important;
}

.meta-info {
	font-size: 18px;
    color: #8d8d8d;
	display: block;
    // font-family: "Poppins";
    text-decoration: none;

}

.blog-single-content .meta-info {
	margin-top: 8px;
	border-bottom: 1px solid #777;
	margin-bottom: 15px;
}

.author {
  display: inline-block;
//   font-weight: 600;
//   font-size: 1.1em !important;	
  margin: 0px 15px 0px 0px;
}

.edit-button{
	margin:0px;
}



.short-description {
    // font-size: 14px;
    // color: #000;
	text-overflow: ellipsis;
	max-width: 15em;
	text-shadow: 0 0 3px rgb(0 0 0);
    white-space: nowrap;
    overflow: hidden;
	padding: 0px 10px 10px 10px;
}

.btn-read {
    background: #544e66 !important;
	@include border-radius(0!important);
	color: #fff!important;
	padding: 5px 10px!important;
	float: left;
	margin-top: 10px;
	font-size: 14px;
}

// Detail

.single{
	// position: relative;
	// top: -55px;
	position: absolute;
	width: 100%;
	display: flex;
    flex-direction: column;
}

.side-menu{
    z-index: 2;
	font-size: 25px;
    color: rgba(255,255,255,0.7);
	display: flex;
    justify-content: flex-end;
    margin-right: 45px;
    margin-top: 30px;
	transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.side-menu:hover{
	color: rgba(255,255,255,0.9);
	transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.side-menu-button img {
	transition: transform 0.2s ease;
  }
  
  .side-menu-button:hover img {
	content: url('../public/eye-closed.png'); /* Replace with the hover image */
	top:2vh;
	margin-top:2vh;
	// padding-top:2vh;
	padding: 30px 11px 0 11px
	// width:80px;
	// height:50px;
  }


.blog-title-box {
    width: 38.2%;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0% 50%;

    .single & {
		position: relative;
		// height: 556px;
		width: 100%;
		// height: 100vh;
		}

    .overlay {
        position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background: rgba(0,0,0,.4);
    }

	.right-arrow:hover{
		// --fa-animation-duration: 2s;
		color:rba(255, 255, 255, 0.9);
		font-size: 50px;
  		transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
	}

	.left-arrow:hover{
		color:rba(255, 255, 255, 0.9);
		font-size: 50px;
  		transition: 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
	}

    .blog-title {
        z-index: 12;
		position: relative;
		// bottom: 35vh;
		// padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);  
  		transition:all 0.2s;

        .single & {
			width: 100%;
			// height: 100%;
			margin: 0vh auto;
			text-align: center;
		}

        h2 {
            color: whitesmoke;
			text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
			font-weight: 300;
            .single & {
				// font-size: 85px;
				font-weight:400;
				font-family: "standard";
				// text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
			}
        }

        > span {
            display: block;
			color: rgba(255,255,255,.5);
			// text-transform: uppercase;
			letter-spacing: .05em;
        }
    }
    
}

.blog-single-content {
    z-index: 999;
	background: var(--grey-accent);
	background-image: url('./assets/images/PAPER2.jpg');
	// margin-top: 26vh;
    position: relative;

	ul li {
		font-size: 1.1em;
	}
	p{
		font-weight: 350;
		color: #322f2aee;
	}
}

// Home
.spinner {
	width: 3rem;
	height: 3rem;
}

// Tags

.tags > a {
    text-decoration: none;
}
.tag {
	background: #f1f1f1;
	padding: 10px 15px;
	color: #222;
	@include transition(0.5s);
	margin-right: 8px;
	margin-top: 5px;
	margin-bottom: 5px;
	display: inline-block;
}
.tag:hover, .tag:focus {
	background: $yellow;
	color: #fff;
	text-decoration: none;
}

// Most popular

.most-popular-img {
    height: 80px;
	width: 100%;
}

.most-popular-font {
   font-weight: 500; 
}

.most-popular-font-meta {
    font-size: 12px;
	color: #777;
}

// Trending
.trending-img-position {
	position: relative;
	overflow: hidden;
	z-index: 5;
	cursor: pointer;
}

.trending-img-size {
	height: 326px;
	overflow: hidden;
}
.trending-img-size > img {
	height: 340px;
	min-width: 100%;
}

.trending-img-relative {
	position: relative;
}

.trending-img-absolute {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	z-index: 7;
}

.trending-img-absolute-1 {
	position: absolute;
	width: 100%;
	z-index: 9;
	padding: 10px;
	bottom: 0;
}

.trending-meta-info {
	color: rgba(255,255,255,0.7);
	font-size: 13px;
}

.trending-img-position:hover {
	-webkit-animation: swing1 1s ease;
	animation: swing1 1s ease;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
 @-webkit-keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}
@keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}

// search
.form-inline {
	display: flex;
	align-items: center;
	width: fit-content;
}

.search-btn {
	float: left;
}

// Category
.widget {
	position: relative;
    display: block;
    margin-bottom: 3rem;
}
.widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.link-widget li {
    padding: 0; //1.2rem 0.8rem 1.7rem;
    text-transform: capitalize;
    line-height: 1;
    font-weight: 500;
    position: relative;
    margin: 0;
    border-bottom: 1px dashed #dadada;
}

.link-widget li:last-child {
    border-bottom: 0 dashed #dadada;
}

.link-widget li span {
    position: absolute;
    right: 0;
}

// Scroll
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	@include transition(.5s);
	cursor: pointer;
	z-index: 1;
	span {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.5);
		color: var(--grey-accent);
		text-align: center;
		@include border-radius(4px);
	}
	i {
		height: 20px;
		display: table-cell;
		vertical-align: middle;
	}
  }

//   Card
.related-content {
	border-radius: 20px;
	cursor: pointer;
  }

// Comment

.custombox {
	position: relative;
    padding: 3rem 2rem;
    border: 1px dashed #dadada;
	margin-top: 20px;
}

.comments-list p {
    margin-bottom: 1rem
}

.comments-list .media-right,
.comments-list small {
    color: #999 !important;
    font-size: 11px;
    letter-spacing: 2px;
    margin-top: 5px;
    padding-left: 10px;
    // text-transform: uppercase;
}

.media {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
		-ms-flex-align: start;
			align-items: flex-start;
  }
  

.comments-list .media {
    padding: 1rem;
    margin-bottom: 15px;
}

.media-body {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0%;
		-ms-flex: 1 1 0%;
			flex: 1 1 0%;
  }

.media-body .btn-primary {
    padding: 5px 15px !important;
    font-size: 11px !important;
}

.comments-list img {
    max-width: 80px;
    margin-right: 30px;
}

.small-title {
    background: #edeff2 none repeat scroll 0 0;
    font-size: 16px;
	font-weight: 700;
    left: 5%;
    line-height: 1;
    margin: 0;
    padding: 0.6rem 1.5rem;
    position: absolute;
    text-align: center;
    top: -18px;
	color:#ffffff !important;
	background-color: #74a044 !important;
	border-color: #74a044 !important;
}

.user_name {
    font-size: 16px;
    font-weight: 600;
}

// Pagination
.btn_mange_pagging {
	background: #dadada;
	padding: 12px 20px;
	color: #222!important;
	font-weight: 800;
	margin: 0 10px;
	border-radius: 10px;
	@include transition(0.5s);
}





.alt-list-left a:hover{
    // color: rgba(0,0,0,0.3);
	color: rgb(255, 255, 255);
    text-shadow: 0px 0px 2px #000000;
    transition: 0.4s cubic-bezier(0.33, 0.66, 0.66, 1);
    // -webkit-text-stroke: 0.5px #FFF;
    // font-size: 30px;
	// transform: scale(1.2);
	
}

.btn_mange_pagging:hover, .btn_mange_pagging:focus {
	background: #222;
	color: #fff!important;
	font-weight: 800;
}

.btn_pagging {
	background: #dadada!important;
	color: #222;
	padding: 12px 20px!important;
	font-weight: 800;
	margin: 0 10px;
	@include transition(0.5s);
}

.btn_pagging:hover, .btn_pagging:focus {
	background: #222!important;
	color: #fff;
}

.scroll {
	width: auto;
	height: 230px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align:justify;
}

//auth


.col-12.text-center{
	font-size:20px
}





.file-input{
	width: 50%;
    display: flex;
    align-items: center;
}



.form-control{
	font-size: 14px;
	border-radius: 0px;
}

.category-select{
	width: 50%;
}

.city-text{
	width:50%;
}


.blog.row{
	display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 10px;
    // height: 480px;
    flex-wrap: nowrap;
}

.row.blog-form{
	display: flex;
	align-items: center;
}

.blogs-flex{
	display: flex;
}

//search button
@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

.search-box {
//   font-size: 30px;
//   border: solid 0.4em var(--grey-accent);
  display: inline-block;
  position: relative;
  border-radius: 2.5em;
  transform:scale(0.5);
  transition: transform 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55),width 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 150ms;
}
.search-box input[type=text] {
	background-color: rgba(0, 0, 0, 0);
	color: #f0f0f0;
	// text-transform: uppercase;
	font-family: 'standard';
	font-weight: 200;
	width: 2.5em;
	height: 2.5em;
	padding: 0em 2.1em 0em 0em;
	border: none;
	box-sizing: border-box;
	border-radius: 2.5em;
	transform:scale(0.8);
	transition: width 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 150ms;
  
}
// .button[type=reset]:focus + .search-box input[type=text]{
// 	transform:scale(0.8);
// }

.search-box input[type=text]:focus {
  outline: none;
//   transform: scale(1.5);
}
.search-box input[type=text]:focus, .search-box input[type=text]:not(:placeholder-shown) {
  transition: width 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
  
}
.search-box input[type=text]:focus + button[type=reset],
.search-box input[type=text]:not(:placeholder-shown) + button[type=reset] {
  transform: scale(0.8) rotate(-45deg) translateY(0);
  transition: transform 150ms ease-out 700ms;
}
.search-box input[type=text]:focus + button[type=reset]:after, .search-box input[type=text]:not(:placeholder-shown) + button[type=reset]:after {
  opacity: 1;
  
  transition: top 150ms ease-out 950ms, right 150ms ease-out 950ms, opacity 150ms ease 950ms;
}
.search-box button[type=reset] {
  background-color: transparent;
  width: 1.4em;
  height: 1.4em;
  border: 0;
  padding: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.55em;
  right: 0.55em;
  transform: rotate(-45deg) translateY(2.2em);
  transition: transform 150ms ease-out 150ms;
}
.search-box button[type=reset]:before,
.search-box button[type=reset]:after {
  content: "";
  background-color: #f1f1f1;
  width: 0.4em;
  height: 1.4em;
  position: absolute;
  
}
.search-box button[type=reset]:after {
  transform: rotate(90deg);
  opacity: 0;
  transition: transform 150ms ease-out, opacity 150ms ease-out;
}

.search-box:has( input:not(:placeholder-shown)),
.search-box:focus-within {
	transform: scale(0.8);
	transition: transform 700ms ease-in;
	// transform: scale(1.5); /* Translate the search box to the right */
}
// .search-box:has( input:not(:placeholder-shown)) {
// 	transition: transform 700ms ease-in;
// 	transform: scale(0.75) translateX(4vw); /* Translate the search box to the right */
// }

//search results
.search-results-header{
	display: flex;

    justify-content: center;
	text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
	padding: 3vh 0vh 1vh 0vw;
    font-family: "cutout";
    color: var(--grey-accent);
	font-weight:300;

}
