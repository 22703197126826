.App {
  text-align: center;
  /* background-image: url('./assets/images/greenoutlineworld.svg');
  background-repeat: no-repeat; */
  /* height:  100vh; */
  height: inherit;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Home-App{
  height: 100%;
  width: 100%;
  /* background-image: url('./assets/images/fairyforest.jpg'); */
    /* background-repeat: no-repeat;
    background-size: auto 100vh;
    background-size:cover;
    background-attachment: fixed; */
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .menu-bar{
  z-index: 10;
  color: rgba(255, 255, 255, 0.20);
  font-size: 22px;
  display: flex;
  justify-content: flex-begin;
  margin-left: 1.5%;
  margin-top: 5%;
  position: relative;
  width: 85px;
  height: 59px;
}

.menu-bar:hover{
  z-index: 10;
  color: rgba(255, 255, 255, 1);

  transition: 0.5s cubic-bezier(0.33, 0.66, 0.66, 1);
} */

.container {
  z-index: 10;
  /* padding-left: 6vw; */
  /* padding-top: 3vh; */
  position: absolute;
  /* max-width: 13vw; */
  top: 0vh;
}