
html{
  padding: 0;
  margin: 0;
  
}
body {
  /* min-height: 100dvh; */
  height:  100%;
  font-family: 'feelings', serif;
  box-sizing: border-box;
  display: flex;
	flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}


body::before {
	content: '';
	position: fixed;
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	 /* background-image: url('./assets/images/collage.jpg'); */
	background-repeat: no-repeat;
	 /* background-size:cover; */
	background-position: center center;
	
	opacity: 1; /* Adjust the opacity as needed */
	z-index: -1;
	/* background-color: rgba(0, 0, 0, 0.7); Black with 50% opacity */
	mix-blend-mode:darken; /*Optional: Controls how the background image blends with the color*/
  }
  @media (max-width: 767px) {
    body::before {
      background-image: url('./assets/images/collageE.jpg');
      background-size:cover;
      /* background-attachment: scroll; */
      }
    }

  @media (min-width: 768px) and (max-width:1279px){
    body::before {
      background-image: url('./assets/images/collageE.jpg');
      background-size:cover;
      background-attachment: fixed;
      }
  }
  @media (min-width: 1280px) {
		body::before {
			background-image: url('./assets/images/collageE.jpg');
      background-size:cover;
      background-attachment: fixed;
			}
      
    }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
height: inherit;
}