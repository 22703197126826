@font-face {
    font-family: 'tropikal';
    src: url('../../assets/fonts/Tropikal-Bold.otf');
}
@media (max-width: 767px) {
    .footer{
        font-size:0.75em;
        padding: 0.5vh 2vw 0.8vh 2vw;
        letter-spacing: 0rem;
        font-weight: 200;

    }
}
@media (min-width: 768px) and (max-width:1279px){
    .footer{
        font-size: 1em;
        padding: 0vh 2vw 0vh 2vw;
        letter-spacing: .1rem;

    }
}
@media (min-width: 1280px) {
    .footer{
        font-size:15px;
        padding:3vh 2vw 1vh 2vw;
        letter-spacing: .1rem;

    }
    


}
.footer{
    /* position: sticky; */
    /* left: 0;
    bottom: 0; */
    width: 100%;
    height: 10vh;
    font-family: 'standard';
    /* background-color: #EAFF82; */
    /* opacity: 15%; */
    color: var(--grey-accent);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}