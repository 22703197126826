  @font-face {
		font-family: 'glassure';
		src: url('../assets/fonts/Glassure.otf');
	}
  /* small mobile */
  @media (max-width: 426px) {
    .squiggle{
      width:100%;
      transform: scaleY(0.5);
    }
    .ticket-head {
      background-position: center;
      background-size: cover;
      border-radius: 0px 0 0 0px;
      color: white;
      width: 100%;
      height: 21%;
      position: absolute;
      top: -21%;
      right: 0%;
    }
    .flight-info {
      width: 53%;
    }
    .ticket-head img{
      width: 100%;
      height: 100%;
      position: relative;
      /* top: -2px; */
  
    }

    .disclaimer h3{
      font-family: 'glassure';
      color:var(--magic-yellow);
      font-style: normal;
      text-align: center;
      font-size: 2.5em;
    }
  
    .disclaimer {
      color: var(--grey-accent);
      /* font-family: "IM Fell French Canon"; */
      font-family: "standard";
      font-size: 16px;
      font-weight:300;
      font-style: italic;
      line-height: 1.2;
      padding: 0px 25px 25px 25px;
      text-transform: none;
      text-align: center;
    }
    .container-fluid-about {
      position: absolute;
      transform: translateY(34%) translateX(10%);
      width: 82%;
    }
    .passenger h4{
      color: var(--magic-yellow);
      font-weight: 500;
      /* text-shadow: 0px 0px 0.4em; */
      font-size: 2.5em;
      font-family: 'glassure';
    }
    .all-flight-info{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2vh;
    }
    .passenger{
      display: flex;
      flex-direction: column;
      /* position: relative;
      top: 45px; */
    }
    .barcode {
      left: 58%;
      top: 14%;
      height: 90px;
      width: 90px;
      position: absolute;
    }
    .ticket {
      display: flex;
      border-radius: 0px;
      /* display: inline-block; */
      /* max-width: 75vw; */
      text-align: left;
      /* height:350px; */
      text-transform: uppercase;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
    }
    .ticket-body {
      display: flex;
      flex-direction: column;
      /* border-bottom: 1px dashed white; */
      padding: 4vh 0vw 2vh 0vw;
      position: relative;
      width: 79%;
      
    }
    .ticket-body p {
      color: #A2A2A2;
      font-size: 12px;
      margin-bottom:0em;
      font-family: "cutout";
    }
    .ticket.dark {
      /* background-color: var(--dark-complement); */
      background-image: url("../assets/images/textures/blackcrinkle.jpg");
      background-size: cover;
      color: white;
      box-shadow: 0px 0px 25px #ffffff0f;
    }
    .flight-info .f-right{
      flex-direction: column;
    }
  }
/* reg mobile */
  @media (max-width: 768px) and (min-width: 426px) {
    .squiggle{
      width:100%;
      transform: scaleY(0.5);
    }
    .ticket-head {
      background-position: center;
      background-size: cover;
      border-radius: 0px 0 0 0px;
      color: white;
      width: 100%;
      height: 40%;
      position: absolute;
      top: -38%;
      right: 0%;
    }
    .flight-info {
      width: 54%;
    }
    .ticket-head img{
      width: 100%;
      height: 100%;
      position: relative;
      /* top: -2px; */
  
    }

    .disclaimer h3{
      font-family: 'glassure';
      color:var(--magic-yellow);
      font-style: normal;
      text-align: center;
      font-size: 2.5em;
    }
  
    .disclaimer {
      color: var(--grey-accent);
      /* font-family: "IM Fell French Canon"; */
      font-family: "standard";
      font-size: 16px;
      font-weight:300;
      font-style: italic;
      line-height: 1.2;
      padding: 0px 25px 25px 25px;
      text-transform: none;
      text-align: center;
    }
    .container-fluid-about {
      position: absolute;
      transform: translateY(53%) translateX(20%);
      width: 71%;
  
    }
    .passenger h4{
      color: var(--magic-yellow);
      font-weight: 500;
      /* text-shadow: 0px 0px 0.4em; */
      font-size: 3em;
      font-family: 'glassure';
    }
    .all-flight-info{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2vh;
    }
    .passenger{
      display: flex;
      flex-direction: column;
      /* position: relative;
      top: 45px; */
    }
    .barcode {
      left: 53%;
      top: 11%;
      height: 110px;
      width: 110px;
      position: absolute;
    }
    .ticket {
      display: flex;
      border-radius: 0px;
      /* display: inline-block; */
      /* max-width: 75vw; */
      text-align: left;
      /* height:350px; */
      text-transform: uppercase;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
    }
    .ticket-body {
      display: flex;
      flex-direction: column;
      /* border-bottom: 1px dashed white; */
      padding: 4vh 0vw 4vh 0vw;
      position: relative;
      width: 74%;
      
    }
    .ticket-body p {
      color: #A2A2A2;
      font-size: 12px;
      margin-bottom:0em;
      font-family: "cutout";
    }
    .ticket.dark {
      /* background-color: var(--dark-complement); */
      background-image: url("../assets/images/textures/blackcrinkle.jpg");
      background-size: cover;
      color: white;
      box-shadow: 0px 0px 25px #ffffff0f;
    }
    .flight-info .f-right{
      flex-direction: column;
    }
  }
  /* tablet */
  @media (min-width: 768px) and (max-width:1279px){
    .squiggle{
      width:100%;
      transform: scaleY(0.5);
    }
    .ticket-head {
      background-position: center;
        background-size: cover;
        border-radius: 0px 0 0 0px;
        color: white;
        width: 27%;
        height: 41%;
        position: absolute;
        top: 7%;
        right: 10%;
  }
    .ticket-head img{
      width: 100%;
      height: 100%;
      position: relative;
      /* right: 1px; */
  
    }

    .disclaimer h3{
      font-family: 'glassure';
      color:var(--magic-yellow);
      font-style: normal;
      text-align: center;
      font-size: 2.5em;
    }
  
    .disclaimer {
      color: var(--grey-accent);
      /* font-family: "IM Fell French Canon"; */
      font-family: "standard";
      font-size: 16px;
      font-weight:300;
      font-style: italic;
      line-height: 1.2;
      padding: 0px 25px 25px 25px;
      text-transform: none;
      text-align: center;
    }
    .container-fluid-about {
      position: absolute;
      transform: translateY(17%) translateX(22%);
      width: 69%;
      height: 85%;
  }

    .passenger h4{
      color: var(--magic-yellow);
      font-weight: 500;
      /* text-shadow: 0px 0px 0.4em; */
      font-size: 3em;
      font-family: 'glassure';
    }
    .all-flight-info{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-bottom: 0vh;
    }
    .flight-info {
      width: 39%;
    }
    .passenger{
      display: flex;
      flex-direction: column;
      /* position: relative;
      top: 45px; */
    }
    .barcode {
      left: 34%;
      top: 12%;
      height: 110px;
      width: 110px;
      position: absolute;
    }
    .ticket {
      display: flex;
      border-radius: 0px;
      /* display: inline-block; */
      /* max-width: 75vw; */
      text-align: left;
      /* height:350px; */
      text-transform: uppercase;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
    }
    .ticket-body {
      display: flex;
      flex-direction: column;
      /* border-bottom: 1px dashed white; */
      padding: 4vh 0vw 1vh 0vw;
      position: relative;
      width: 74%;
      
    }
    .ticket-body p {
      color: #A2A2A2;
      font-size: 12px;
      margin-bottom:0em;
      font-family: "cutout";
    }
    .ticket.dark {
      /* background-color: var(--dark-complement); */
      background-image: url("../assets/images/textures/blackcrinkle.jpg");
      color: white;
      background-size: contain;
      box-shadow: 0px 0px 25px #ffffff0f;
    }
    .flight-info .f-right{
      flex-direction: column;
    }

  }
  /* desktop */
  @media  (min-width: 1280px) {
    .squiggle{
      position: absolute;
      left: 51%;
        top: 44%;
        width: 100%;
        transform: rotate(90deg) scaleY(0.4)
    }
    .ticket-container{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .container-fluid-about {
    position: absolute;
    left: 50%;
    top: 59%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
  }
  .ticket {
    display: flex;
    border-radius: 0px;
    /* display: inline-block; */
    max-width: 75vw;
    text-align: left;
    /* height:350px; */
    text-transform: uppercase;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  .ticket.dark {
    background-image: url("../assets/images/textures/blackcrinkle.jpg");
    color: white;
    box-shadow: 0px 0px 25px #ffffff0f;
    background-size: cover;
  }
  /* .ticket.light {
    background-color: white;
    color: #161616;
  }
  .ticket.light .ticket-body {
    border-color: #161616;
  } */
  .ticket-head {
    background-position: center;
    background-size: cover;
    border-radius: 0px 0 0 0px;
    color: white;
    width: 142vw;
    height: 100%;
    position: relative;
  }
  .ticket-head img{
    height:100%;
    width:100%;
  }
  .ticket-head .from-to {
    /* text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.9); */
    font-size: 1.8em;
    font-weight: 600;
    /* font-family: 'glassure'; */
    width: 100%;
    display: flex;
    padding-bottom: 76%;
    height: inherit;
    color: #000000;
    z-index: 2;
    justify-content: center;
    align-content: space-around;
    align-items: center;
  }
  .fa-plane{
    color:var(--dark-complement);
  }
  .from-location{
    width:35%;
    text-align: center;

  }
  .to-location{
    width:35%;
    text-align: center;
  }
  .ticket-head .icon {
    display: inline-block;
    margin: 0 40px;
    transform: rotate(135deg) translate(25%, 25%);
  }
  .ticket-body {
    display: flex;
    flex-direction: column;
    /* border-right: 1px dashed white; */
    padding: 4vh 3vw 4vh 3vw;
    position: relative;
    
  }
  .ticket-body p {
    color: #A2A2A2;
    font-size: 12px;
    font-family: "cutout";
  }
  .all-flight-info{
    display: flex;
    justify-content: space-between;
    padding-bottom: 2vh;
    width: 19vw;
  }
  .ticket-body .flight-info {
    margin-top: 15px;
    flex-direction: column;
  }
  .ticket-body .flight-info .info, h4, p {
    width: fit-content;
    font-weight: 300;
  }

  .ticket-body .flight-info .f-right{
    padding-left:2vw;

  }
  .ticket-body .flight-date {
    font-size: 12px;
    margin-top: 15px;
  }
  .passenger{
    display: flex;
    flex-direction: column;
    /* position: relative;
    top: 45px; */
  }
  .passenger h4{
    color: var(--magic-yellow);
    font-weight: 500;
    /* text-shadow: 0px 0px 0.4em; */
    font-size: 3.5em;
    font-family: 'glassure';
  }
  .ticket-body:before,
  .ticket-body:after {
    /* background-color: #FFB563; */
    border-radius: 100%;
    content: "";
    height: 15px;
    position: absolute;
    top: 100%;
    width: 20px;
  }
  .ticket-body:before {
    left: 0;
    /* transform: translate(-70%, -45%); */
  }
  .ticket-body:after {
    right: 0;
    /* transform: translate(70%, -45%); */
  }
  .barcode {
    left: 58%;
    top: 12%;
    height: 120px;
    width: 120px;
    position: absolute;
  }
  
  .disclaimer h3{
    font-family: 'glassure';
    color:var(--magic-yellow);
    font-style: normal;
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.05em;
  }

  .disclaimer {
    color: var(--grey-accent);
    /* font-family: "IM Fell French Canon"; */
    font-family: "standard";
    font-size: 1em;
    font-weight: 200;
    text-align: center;
    /* font-style: italic; */
    line-height: 1.2;
    padding: 15px 25px;
    text-transform: none;
  }
  .layer {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px 0 0 7px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  
  }

   /* body { */
    /* background-color: #FFB563; */
    /* font-family: "Montserrat", "Helvetica Neue", "Open Sans", "Arial";
    font-weight: 300; */
  /* } */