/* @font-face {
    font-family: 'bungee-shade';
    src: url('../assets/fonts/BungeeShade-Regular.ttf');
} */

.not-found{
    /* font-family: 'bungee-shade'; */
    font-size: 80px;
    height: 60%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
