
@font-face {
    font-family: 'glassure';
    src: url('../../assets/fonts/Glassure.otf');
}

@font-face {
    font-family: 'calligraffitti';
    src: url('../../assets/fonts/Calligraffitti-Regular.ttf');
}

@font-face {
    font-family: 'gela';
    src: url('../../assets/fonts/GelaTrialVF.ttf');
}

@font-face {
    font-family: 'gela';
    src: url('../../assets/fonts/GelaTrialVF.ttf');
}

@font-face {
    font-family: 'dancing';
    src: url('../../assets/fonts/DancingScript-VariableFont_wght.ttf');
}
.big-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* letter-spacing: 0.5em; */
    /* font-weight: 100; */
}
/* Show on small screens (768px and below) */
@media (max-width: 767px) {
    .big-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: inherit;
        align-items: flex-start;
        padding-bottom: 3vh;
        
        /* letter-spacing: 0.5em; */
        /* font-weight: 100; */
        /* width: 45%; */
    }
    .the{
        font-size: 0.3em;
        padding-right:0.2em;
        display: flex;
        justify-content: flex-start;
        /* padding: 11vh 0.5vw 0px 2vw; */
        vertical-align: top;
        align-items: flex-start;
        position: absolute;
        transform: translate(-100%, -300%);
    
    }
    
    .tree-chronicles{
        font-family: 'glassure';
        font-size: 4em;
        font-weight: 100;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: var(--magic-yellow);
        line-height: 1.05;
        position: relative;
    }
  }
  @media (min-width: 768px) and (max-width:1279px){
    .big-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: inherit;
        align-items: flex-start;
        padding-bottom: 3vh;
        
        /* letter-spacing: 0.5em; */
        /* font-weight: 100; */
        /* width: 45%; */
    }
    .the{
        font-size: 2em;
        padding-right:0.2em;
        color: var(--magic-yellow);
        justify-content: flex-start;
        /* padding: 11vh 0.5vw 0px 2vw; */
        vertical-align: top;
        align-items: flex-start;
        position: absolute;
        transform: translate(-415%, -25%);
    
    }
    
    .tree-chronicles{
        position: relative;
        font-size: 6em;
        font-weight: 100;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: var(--magic-yellow);
        line-height: 1.05;
    }
}
  @media (min-width: 1280px) {
    .the{
        font-size: 2em;
        justify-content: flex-start;
        vertical-align: top;
        align-items: flex-start;
    }
    .tree-chronicles{
        font-size: 7em;
    }
  }


.the{
    font-family: 'dancing';
    display: flex;
    letter-spacing: normal;
    font-weight:lighter;
    color: var(--magic-yellow);
}

.tree-chronicles{
    font-family: 'glassure';
    font-weight: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--magic-yellow);
    line-height: 1.05;
}
