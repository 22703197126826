@font-face {
    font-family: 'ruslan-display';
    src: url('../../assets/fonts/RuslanDisplay-Regular.ttf');
}

/* @font-face {
    font-family: 'bungee-inline';
    src: url('../../assets/fonts/BungeeInline-Regular.ttf');
} */

@font-face {
    font-family: 'crimson';
    src: url('../../assets/fonts/CrimsonText-Regular.ttf');
}

@font-face {
    font-family: 'poppins';
    src: url('../../assets/fonts/Poppins-Regular.ttf');
}
/* 
@font-face {
    font-family: 'bungee-shade';
    src: url('../../assets/fonts/BungeeShade-Regular.ttf');
} */

@font-face {
    font-family: 'tropikal';
    src: url('../../assets/fonts/Tropikal-Bold.otf');
}
@font-face {
    font-family: 'cutout';
    src: url('../../assets/fonts/Magazine-Cutouts-Font.ttf');
}

@font-face {
	font-family: 'feelings';
	src: url('../../assets/fonts/FeelingsOnOff.ttf');
}

@font-face {
	font-family: 'justaword';
	src: url('../../assets/fonts/Justaword.ttf');
}


@font-face {
    font-family: 'signika';
    src: url('../../assets/fonts/Signika.ttf');
}
@font-face {
	font-family: 'standard';
	src: url('../../assets/fonts/JosefinSans.ttf');
}
/* @font-face {
	font-family: 'standard';
	src: url('../../assets/fonts/raleway.ttf');
} */

/* .user-name{
    font-family:"poppins";
} */
.log-out{
    /* margin-bottom: 1rem; */
    color:var(--grey-accent);
    /* font-family:"poppins"; */

}
.std-nav-bar-wrapper{
    width:inherit;
    /* height: 49px; */
    text-align: center;
    flex-shrink: 0;
    /* margin-top: 2%; */
    /* height: 85px; */
    display: flex;
    justify-content: space-between;
    z-index: 12;
    align-items: center;
    
}

@media (max-width: 767px) {
    ul{
        margin-top:0vh;
    }
    .std-nav-bar-wrapper{
        width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0vh 2vw;
      /* padding: 0vh 2vw 0vh 2vw; */
    }
    .list-right{
        padding-right:2vw;
        width:100vw;
        display:inline;
    }
    .list-left a{
        padding: 0em 0.4em;
        font-size: 0.5em;
    }
    .std-nav-bar-wrapper .list-left{
        padding-left:1em;
    }
  }
  @media  (min-width: 768px) and (max-width:1279px){
    ul{
        margin-top:1vh;
    }
    .std-nav-bar-wrapper{
        width: 100%;
        padding: 0vh 2vw;
        }
        .list-right{
            padding-right:1vw;
            width:100vw;
            display:inline;
        }
        .list-left a{
            padding: 0em 0.4em;
            font-size: 0.5em;
        }
        .std-nav-bar-wrapper .list-left{
            padding-left:1em;
        }
}
  @media (min-width: 1280px) {
    ul{
        margin-top:1vh;
    }
    .list-right{
        float:right;
        padding-right: 1rem;
    }
    /* .std-nav-bar-wrapper{ */
        /* width: 100vw; */
    /* } */
    .list-left a{
        padding: 0em 0.4em;
        font-size: 2em;
    }
    .std-nav-bar-wrapper .list-left{
        padding-left:2em;
    }
}

.search-box input::placeholder{
    font-weight:400;
    color:var(--grey-accent);
    text-shadow:0 0 2px black;
}
.list-left a:hover{
    /* // color: rgba(0,0,0,0.0); */
	color: whitesmoke;
    text-shadow: 0px 0px 1px #000000;
    transition: 0.4s cubic-bezier(0.33, 0.66, 0.66, 1);
    /* // -webkit-text-stroke: 1px #1E1B0F;
    // font-size: 30px;
	// transform: scale(1.1); */
}
.overlay-markup {
    position: absolute;
    top: 25%;
    left: 10%;
    transform: translate(-50%, -50%); 
    filter: invert(1);
    width: 25px;
    height: 25px;
    opacity: 0;
    transition: opacity 0.2s ease-in;
    /* z-index: -1; */
  }

  .list-left li:hover .overlay-markup {
    opacity: 1;
  }

.font-icon{
    font-size: 25px;
}
.list-left a{
    display: flex;
    /* position: relative; */
    align-items: center;
    color: #e3e3e3;
    font-family: 'cutout';
}


.list-left li{
    position: relative; 
   
}

.list-right{
    /* flex-grow: 1;  */
    padding-left: 0rem;
    float:right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "cutout";
    /* font-weight: 500; */
    font-size: 22px;
}
.list-middle{
    width: 0vw;
}
ul{
    /* margin-top:1vh; */
    list-style-type: none;
    display: flex;
    margin-bottom: 0rem;
    align-items: center;
    
}
nav{
    display: flex;
    justify-content: center;
}



a:link{
    text-decoration: none;
}

a{
    color: var(--grey-accent);
    
}

 

/* .list-right a:hover{ */
    /* color: rgba(0,0,0,0.3); */

/* } */

.log-out:hover{
    color: whitesmoke;
    text-shadow: 1px 1px 7px #939393;
    transition: 0.4s cubic-bezier(0.33, 0.66, 0.66, 1);
    /* -webkit-text-stroke: 0.5px whitesmoke; */
	/* transform: scale(1.1); */
}