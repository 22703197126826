.no-scroll{
  overflow: hidden;
}
@media (max-width: 549px) {
  .blog-overlay::before {
    top: 0vh;
    left: 0vw;
    right: 0;
    bottom: 0px;
    transform-origin: center;
    transform: scaleX(1.3) scaleY(4) rotate(-4deg);
    background-image: url("../assets/images/rip4.png");

  }
  .blog-overlay {
    position: absolute;
    bottom: 0%;
    /* left: 70%; */
    transform: translateX(0%) scale(0.7);
    z-index: 10;
    width: 100vw;
    padding: 0 3vw;

}
  .blog-header{
    opacity: 0.8;
    font-size: 3em;
    top: 6vh;
    position: relative;
  }
 
}
@media (min-width: 550px) and (max-width: 767px) {
  .blog-overlay::before {
    top: 0vh;
    left: 0vw;
    right: 0;
    bottom: 0px;
    transform-origin: center;
    transform: scaleX(1.3) scaleY(2.2) rotate(-4deg);
    background-image: url("../assets/images/rip4.png");

  }
  .blog-overlay {
    position: absolute;
    bottom: 0%;
    /* left: 70%; */
    transform: translateX(0%) scale(0.7);
    z-index: 10;
    width: 100vw;
    padding: 0 3vw;

}
  .blog-header{
    opacity: 0.8;
    font-size: 3em;
    top: 6vh;
    position: relative;
  }
 
}
/* tablet view */
@media (min-width: 768px) and (max-width: 1279px) {
  .blog-overlay::before {
    top: 2vh;
    left: 0vw;
    right: 0;
    bottom: 0;
    transform-origin: center;
    transform: scaleX(1.6) scaleY(1.2);
    background-image: url("../assets/images/rip5.png");

}
  .blog-header{
    justify-content: flex-start;
    text-shadow: 0px 0px #0f0a0d88;
    letter-spacing: 0rem;
    font-size: 3em;
    padding: 30px 20px 0px 0px;
  }
  .blog-overlay {
    position: absolute;
      bottom: -9%;
      /* left: 50%; */
      transform: translateX(0%) scale(0.7);
      z-index: 10;
      width: 80vw;
      padding: 0 12vw;
  }
  
  
}
/* desktop */
@media (min-width: 1280px) {
  .blog-overlay::before {
    top: 2vh;
    left: 0vw;
    right: 0;
    bottom: 0;
    transform-origin: center;
    transform: scaleX(2.2) scaleY(1.2);
    background-image: url("../assets/images/rip5.png");
}
  .blog-header{
    justify-content: flex-start;
    text-shadow: 0px 0px #0f0a0d88;
    letter-spacing: 0rem;
    font-size: 80px;
    padding: 20px 20px 0px 0px;
  }
  .blog-overlay {
    position: absolute;
      bottom: -9%;
      /* left: 50%; */
      transform: translateX(0%) scale(0.7);
      z-index: 10;
      width: 80vw;
      padding: 0 8vw;
  }
  
  
}
.map-container {
  position: absolute;
  /* transition: transform 1s ease-in-out;  */
  /* Adjust timing as needed */

}


.blog-header{
  display: flex;
  color: #1b1a16;
  justify-content: flex-start;
  font-family: "cutout";
  text-shadow: 0px 0px #0f0a0d88;
  letter-spacing: 0rem;
  /* background-image: url("../assets/images/rip4.png"); */
  text-transform: uppercase;
  width:fit-content;
}

.blog-overlay::before {
    content: '';
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    transform-origin: center;
    pointer-events: none;
}

