
/* mobile view */
  @media (max-width: 767px) {
    .side-bar-list-down {
      padding-top: 0vh;
    }
    .side-bar-list-down li a {
      font-size: 3.3em;
      /* -webkit-text-stroke: 0.4px rgba(224, 224, 224, 0.9); */
      color:#ffe55088;
    }
  }
  /* tablet view */
  @media  (min-width: 768px) and (max-width:1279px){
    .side-bar-list-down {
      padding-top: 2vh;
    }
    .side-bar-list-down li a {
      font-size: 4em;
      color:#ffe55088;
    }
    .side-menu-button {
      margin-top:1vh;
    }
    
  }
  /* desktop */
	@media  (min-width: 1280px) {
    /* .side-bar-list-down {
      padding-top: 6vh;
    } */
		.side-bar-list-down li a {
      font-size: 4em;
      -webkit-text-stroke: 0.7px rgba(224, 224, 224, 0.8);
      color:transparent;
    }
    
    
    .side-bar-list-down li a:hover::before {
      width: 100%;
      filter: drop-shadow(0 0 50px var(--magic-yellow));
    }
    
    
	}
  .side-bar-list-down li a:hover {
    color: var(--magic-yellow);
    -webkit-text-stroke: 0px;
    filter: drop-shadow(0 0 2px var(--magic-yellow));
  }

.side-bar-list-down {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-right: 40px;
  }
  
  .side-bar-list-down li {
    position: relative;
    list-style: none;
  }
  .side-bar-list-down li a::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: var(--magic-yellow);
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: width 1s ease;
    border-right: 8px solid var(--magic-yellow);
    -webkit-text-stroke: 1px var(--magic-yellow);
  }
  
  .side-bar-list-down li a {
    text-decoration: none;
    letter-spacing: 3px;
    line-height: 1em;
    /* text-transform: uppercase; */
    /* color: transparent; */
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-family: 'glassure';
  }
  

  .side-bar-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .side-bar-list a {
    
    padding-left: 0.75em;

    color: var(--dark-complement);
  }
  